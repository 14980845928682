import React from "react";
import "./Hero.scss";
import socialLinks from "../../../../SocialLints";

const Hero = () => {
  return (
    <>
      <section className="hero">
        <header>
          <h1>
            Revolutionize Your DeFi Experience with <br /> Short Swap
          </h1>
          <p>Unlocking the Power of Short Selling on the Blockchain.</p>
          <button className="primary-btn outlined">Start Trading</button>
        </header>
        <ul className="flex flex-col items-center gap-6 mb-10 md:mb-0 social-strip">
          {socialLinks.map(({ link, icon, label }, index) => (
            <li key={index}>
              <a href={link} target="_blank" rel="noopener noreferrer">
                <img src={icon} alt={label} />
              </a>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default Hero;
