import React from "react";
import plusIcon from "../../../../assets/plus.svg";
import minusIcon from "../../../../assets/minus.svg";
import AnimateHeight from "react-animate-height";
import "./Faq.scss";
import glow from "../../../../assets/orange-glow.png";

const Faq = () => {
  const [height, setHeight] = React.useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
  });
  const toggleHeight = (id) => {
    if (height[id] === 0) {
      setHeight({ ...height, [id]: "auto" });
    } else {
      setHeight({ ...height, [id]: 0 });
    }
  };
  const data = [
    {
      id: 1,
      question: "What is a DEX (decentralized exchange)?",
      answer: (
        <p>
         A peer-to-peer marketplace that relies on smart contracts 
         self-executing code on the blockchain), to allow seamlessly 
         and immutable transactions for both buyers and sellers alike.
        </p>
      ),
    },
    {
      id: 2,
      question: "What is shorting a stock/token?",
      answer: (
        <p>
         When you borrow shares or tokens of a company or project to sell at the 
         current market price.
        </p>
      ),
    },
    {
      id: 3,
      question: "How can you just borrow shares or tokens to sell?",
      answer: (
        <div>
          <p>
            When you borrow shares or tokens of a company or project to sell at
            the current market price.
          </p>
        </div>
      ),
    },
    {
      id: 4,
      question: "Why is this done?",
      answer: (
        <p>
          Because you anticipate the price to go down and you intend to buy 
          the shares or tokens you borrowed back at a lower price.
        </p>
      ),
    },
    {
      id: 5,
      question:
        "What happens if the price goes up?",
      answer: (
        <p>
          You can willingly buy back the shares or tokens you borrowed from the open 
          market and take your loss or if your collateral is no longer sufficient to 
          maintain your position, a market buy will be triggered using your collateral 
          to replace the borrowed shares or tokens.
        </p>
      ),
    },
    {
      id: 6,
      question:
        "How will it work?",
      answer: (
        <p>
          Taking existing DEX technology and adding a new layer. Instead of just seeing 
          a swap option like you see now on existing DEXs, there will be another option 
          called SHORT. From here you can pick the token of your choice and intended 
          position (collateral). Let’s say you put 1 ETH, the protocol will determine 
          the amount of tokens you will short. This will be calculated by an algorithm 
          that factors in liquidity, volume and other factors. It will also let you know 
          at the price your collateral will be used to cover (buy back) the tokens you 
          borrowed in the event the price continues to rise. There will also be an option
          to automatically take profit and buy back the returned tokens at a set price, 
          in which event the protocol will return your collateral along with your profit.
        </p>
      ),
    },
    {
     id: 7,
      question:
        "Can you add collateral to position to prevent automatic buyback?",
      answer: (
        <p>
          Yes, there will be another tab for active positions that will show your liquidation 
          price, current profit/loss and also the option to add collateral to allow for more 
          price movement in your current position.
        </p>
      ),
    },
  ];
  
  return (
    <section className="faq relative">
      <img
        src={glow}
        alt="glow"
        className="absolute left-[-100px] top-[-250px] md:top-[-90%] md:w-[60%]"
      />
      <h2>
        Frequently Asked <br /> Questions
      </h2>
      <div className="flex flex-col gap-6">
        {data.map(({ question, answer, id }, index) => (
          <div
            className={height[id] === 0 ? "faq-card" : "faq-card active"}
            key={index}
          >
            <div className="title">
              <h5 className="question">{question}</h5>
              <img
                src={height[id] === 0 ? plusIcon : minusIcon}
                alt="arrow down"
                className="img-fluid cursor-pointer"
                onClick={() => toggleHeight(id)}
              />
            </div>
            <AnimateHeight duration={500} height={height[id]}>
              {answer}
            </AnimateHeight>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Faq;
