import React from "react";
import "./HowItWorks.scss";
import walletIcon from "../../../../assets/wallet.svg";
import tokenAllocaitonIcon from "../../../../assets/token-allocation.svg";
import tokenDownIcon from "../../../../assets/token-down.svg";

const HowItWorks = () => {
  return (
    <section className="how-it-works">
      <div className="px-10">
        <div>
          <small className="block mb-3">The Short unique</small>
          <h2>How it Works?</h2>
          <p className="md:max-w-[70%]">
            Working with existing liquidity pools, short sellers pay a small fee
            to borrow tokens of a particular project with the intent of
            profiting off of a price decline. Lenders make this possible by
            depositing tokens they already own, with the expectation of
            receiving a fee for lending out their assets.
            <br />
            Short Swaps protocol and lending pools make all of this as simple
            and secure as a traditional swap mechanism on your favorite DEX.
          </p>
        </div>
        <div className="grid md:grid-cols-3 gap-16 pt-14">
          <div className="how-card">
            <img src={walletIcon} alt="Connect Wallet " />
            <h4>Connect Wallet</h4>
            <p>
              Select your wallet from the list to connect to the protocol.
            </p>
          </div>
          <div className="how-card">
            <img src={tokenAllocaitonIcon} alt="Buy Token Allocation" />
            <h4>Select Token</h4>
            <p>
              Choose which token you intend to short-sell. 
              Enter the number of tokens to short, deposit eth as 
              collateral and create your position.

            </p>
          </div>
          <div className="how-card">
            <img src={tokenDownIcon} alt="Token Goes Down" />
            <h4>Token Price Goes Down</h4>
            <p>
              From the dApp page, watch your position’s profit 
              increase as the token price goes down. Close your position and receive the eth you 
              have profited.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
