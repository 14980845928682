import React from "react";
import Hero from "./components/hero/Hero";
import HowItWorks from "./components/how-it-works/HowItWorks";
import ShortSwap from "./components/short-swap/ShortSwap";
// import IconSection from "./components/icon-section/IconSection";
import NavigationBar from "../../components/NavigationBar";
import Footer from "../../components/footer/Footer";
import "./Home.scss";
import glow from "../../assets/top-glow.png";
import Faq from "./components/faq/Faq";

const Home = () => {
  return (
    <>
      <div className="max-w-[90%] mx-auto home">
        <img src={glow} alt="glow" className="top-glow" />
        <div className="home-contents">
          <NavigationBar />
          <Hero />
          <HowItWorks />
          <ShortSwap />
          {/* <IconSection /> */}
          <Faq />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Home;
