import React from "react";
import "./ShortSwap.scss";
import formImg from "../../../../assets/form.svg";

const ShortSwap = () => {
  const content = [
    {
      title: "Short Selling on the Blockchain",
      description:
        "Short Swap’s revolutionary lending pools make it possible to immutably short tokens.",
    },
    {
      title: "User Friendly Interface",
      description:
        "Our simple and straightforward design make it easy to borrow or lend any tradeable token.",
    },
    {
      title: "Profits & Fees",
      description:
        "Lenders and borrowers have the option to receive their fees or profits in either $short or ethereum.",
    },
  ];
  return (
    <section className="grid md:grid-cols-2 gap-20 py-32">
      <div>
        <img src={formImg} alt="form" />
      </div>
      <div>
        <ul className="content">
          {content.map(({ title, description }, index) => (
            <li key={index}>
              <h4>{title}</h4>
              <p>{description}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default ShortSwap;
